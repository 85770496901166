import React, { useState } from 'react';
import { eliminaPalabrasInnecesarias } from '../../helpers';
import SongContext from './songContext';
// import { DEBUG_RED, DEBUG_GREEN } from '../../types';

const SongActions = (props) => {
	const [words, setWords] = useState([]);
	const [phrases, setPhrases] = useState([]);
	// const [artist, setArtist] = useState('');
	// const [songTitle, setSongTitle] = useState('');

	const cleanLyrics = (lyr) => {
		// const cleanLyrics = (lyr, artist, songTitle) => {
		// Replace all special characters
		const str = lyr.replace(/[^ñáéíóú\w\s]/gi, ' ');

		// Replace all new lines or empty lines
		let strWithNoNewLines = str
			.replace(/[\r\n+]/gm, '$')
			.replace(/\${2}/gi, '$');
		// strWithNoNewLines has no new lines or empty lines, instead, '$' represents a new line so I can split it there and get the phrases on the next step:

		setPhrases([...new Set(strWithNoNewLines.toLowerCase().split('$'))]);

		let lyricWordsClean = [];
		let lyricWords = eliminaPalabrasInnecesarias(str.replace(/[\r\n+]/gm, ' '))
			.split(' ')
			.forEach((word) => {
				if (word.length > 0) {
					return lyricWordsClean.push(word.toLowerCase().trim());
				}
			});

		console.info(
			'CLEAN LYRICS',
			lyricWordsClean,
			lyricWordsClean.join(' ').replace(/[\r\n+]/gm, ' ')
		);
		setWords([
			...new Set(
				eliminaPalabrasInnecesarias(
					lyricWordsClean.join(' ').replace(/[\r\n+]/gm, ' ')
				)
					.replace(/,|\$/g, '')
					.toLowerCase()
					.split(' ')
			),
		]);

		// setArtist(artist);
		// setSongTitle(songTitle);
	};

	const removeWords = (word) => {
		// remove word from array
		words.splice(words.indexOf(word), 1);
		// console.log('%c words[] after removeWords():', DEBUG_RED, words);
		setWords(words);
	};

	const removePhrases = (phrase) => {
		// remove phrase from array
		phrases.splice(phrases.indexOf(phrase), 1);
		// console.log('%c phrases[] after removephrases():', DEBUG_RED, phrases);
		setPhrases(phrases);
	};
	return (
		<SongContext.Provider
			value={{
				cleanLyrics,
				words,
				phrases,
				removeWords,
				removePhrases,
				// artist,
				//songTitle,
			}}>
			{props.children}
		</SongContext.Provider>
	);
};

export default SongActions;
