import React from 'react';
import { Link } from 'react-router-dom';

// ===== COMPONENTS =====

const Search = (props) => {
	return (
		<React.Fragment>
			<div className='card'>
				<div className='content'>
					<Link className='btn' to='/search'>
						Search
					</Link>
					<Link className='btn' to='/add'>
						Add Lyrcis
					</Link>
				</div>
			</div>

			<div className='card'>
				<div className='content'>
					<a href='http://cea.cide.edu/gramtica/tipos_de_palabras.html'>
						palabras
					</a>
					<a href='https://3.bp.blogspot.com/-Y2dI4xJNNRw/XGBjbPKy7bI/AAAAAAAAAKk/zoLokXa-5is3dE0_HDRsQTWpM0ezWBAIgCLcBGAs/s1600/clases%2Bde%2Bpalabras.jpg'>
						Clases de Palabras (IMAGE)
					</a>
					<a href='https://www.claseslengua.com/2019/02/clases-de-palabras-y-grupos-de-palabras.html'>
						Sintagmas
					</a>
					<a href='https://www.gramaticas.net/2012/11/tipos-de-palabras.html'>
						Tipos de palabras
					</a>
					<a href='https://www.gramaticas.net/2010/09/ejemplos-de-sintagma.html'>
						Ejemplos de Sintagma
					</a>
					<a href='https://www.edu.xunta.gal/centros/iesillaons/system/files/CLASES+DE+PALABRAS.+(T).pdf'>
						PDF
					</a>

					<br />
				</div>
			</div>
		</React.Fragment>
	);
};

export default Search;
