import React, { Fragment, useContext, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { DEBUG_BLUE } from '../utils/types';
import SongContext from '../utils/context/analyzeSongs/songContext';
import firebaseContext from '../utils/context/firebase/firebaseContext';
import Li from './Li_Tag';
// import { useEffect } from 'react';

const Results = (props) => {
	const { words, phrases, artist, songTitle } = useContext(SongContext);
	const { addWords, addPhrases } = useContext(firebaseContext);
	const [showWords, setShowWords] = useState(true);
	const [showPhrases, setShowPhrases] = useState(true);

	// useEffect(() => {
	// 	console.log('%c In Results:', DEBUG_BLUE, words);
	// });

	const handleAddWords = () => {
		let isConfirmed = window.confirm(
			'Did you check the spelling of each word?'
		);
		if (isConfirmed) {
			addWords(words);
			setShowWords(false);
		}
	};
	const handleAddPhrases = () => {
		addPhrases(phrases);
		setShowPhrases(false);
	};

	return (
		<Fragment>
			<div className='output'>
				{showWords && (
					<div className='card words'>
						<h3>Words</h3>
						<ul className='content'>
							{words
								? words.map((word, key = uuidv4()) => {
										console.info(word, word.length);
										return (
											<Li value={word} key={key} actions={true} words={words} />
										);
								  })
								: 'No words'}
						</ul>
						<div className='content'>
							<button className='primary' onClick={handleAddWords}>
								Add Words
							</button>
						</div>
					</div>
				)}
				{showPhrases && (
					<div className='card phrases'>
						<h3>Phrases</h3>
						<ul className='content'>
							{phrases
								? phrases.map((phrase, key = uuidv4()) => {
										return (
											<Li
												value={phrase}
												key={key}
												actions={true}
												phrases={phrases}
											/>
										);
								  })
								: 'No phrases'}
						</ul>
						<div className='content'>
							<button className='primary' onClick={handleAddPhrases}>
								Add Phrases
							</button>
						</div>
					</div>
				)}
			</div>
		</Fragment>
	);
};

export default Results;
