import {
	GET_RIMAS,
	GET_WORDS_ARRAY,
	GET_PHRASES_ARRAY,
	// DEBUG_GREEN,
	// DEBUG_GREY,
	// DEBUG_YELLOW,
	// DEBUG_BLUE,
	// DEBUG_RED,
} from '../../types';

export default (state, action) => {
	// console.log(
	// 	'%c ACTION.PAYLOAD (In firebaseReducer):',
	// 	DEBUG_BLUE,
	// 	action.payload
	// );
	// console.log('%c STATE (In firebaseReducer):', DEBUG_BLUE, state);

	switch (action.type) {
		case GET_RIMAS:
			action.payload.forEach((doc) => {
				if (doc.id === 'words') {
					state.words = [...doc.words];
				}
				if (doc.id === 'phrases') {
					state.phrases = [...doc.phrases];
				}
			});
			console.log('Words[]:', state.words);
			console.log('Phrases[]:]', state.phrases);
			return {
				...state,
				rimas: action.payload,
			};
		case GET_WORDS_ARRAY:
			return {
				...state,
				words: action.payload,
			};
		case GET_PHRASES_ARRAY:
			return {
				...state,
				phrases: action.payload,
			};
		default:
			return state;
	}
};
