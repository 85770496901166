import React, { useReducer } from 'react';
import firebase from '../../firebaseConfig';
import FirebaseContext from './firebaseContext';
import firebaseReducer from './firebaseReducer';
import {
	GET_RIMAS,
	GET_WORDS_ARRAY,
	GET_PHRASES_ARRAY,
	DEBUG_GREEN,
	DEBUG_GREY,
	DEBUG_RED,
	DEBUG_BLUE,
} from '../../types';

const FirebaseActions = (props) => {
	const initialState = {
		rimas: [],
		words: [],
		phrases: [],
	};

	const [state, dispatch] = useReducer(firebaseReducer, initialState);

	// ==========  FIREBASE ACTIONS ==========
	// #region ===== GET/READ ACTIONS

	const getRimas = () => {
		// WORKING: gets entire 'rimas' collection, which has multiple documents
		firebase
			.firestore()
			.collection('rimas')
			.onSnapshot((snapshot) => {
				const rimasInDB = snapshot.docs.map((doc) => ({
					id: doc.id,
					...doc.data(),
				}));

				dispatch({
					type: GET_RIMAS,
					payload: rimasInDB,
				});
				// console.log('%c Rimas Collection:', DEBUG_GREY, rimasInDB);
			});
	};

	const getWordsFromRimas = () => {
		firebase
			.firestore()
			.collection('rimas')
			.doc('words')
			.get()
			.then((doc) => {
				let wordsInFirestore = [];
				if (doc.exists) {
					const obj = doc.data();
					wordsInFirestore = [...Array.from(obj.words)];
				} else {
					console.log('No such document!');
				}

				dispatch({
					type: GET_WORDS_ARRAY,
					payload: wordsInFirestore,
				});
			});
	};

	const getPhrasesFromRimas = () => {
		firebase
			.firestore()
			.collection('rimas')
			.doc('phrases')
			.get()
			.then((doc) => {
				let phrasesInFirestore = [];
				if (doc.exists) {
					const obj = doc.data();
					phrasesInFirestore = [...Array.from(obj.phrases)];
					// console.log('%c phrases[]:', DEBUG_GREY, phrasesInFirestore);
				} else {
					console.log('No such document!');
				}

				dispatch({
					type: GET_PHRASES_ARRAY,
					payload: phrasesInFirestore,
				});
			});
	};
	// #endregion

	// #region ===== Add ACTIONS
	const addWords = (words2Add) => {
		// WORKING! updates words document in firestore
		// console.log('%c words[] to add in addWords():', DEBUG_GREY, words2Add);
		let obj = {};
		let wordsInFirestore = [];

		// Step 1: get the current words array from Firestore
		firebase
			.firestore()
			.collection('rimas')
			.doc('words')
			.get()
			.then((doc) => {
				if (doc.exists) {
					obj = doc.data();
					console.log(obj);
					wordsInFirestore = [...Array.from(obj.words)];
				} else {
					console.log('No such document!');
				}

				cleanup();
				updatewordsInFirestore();
			})
			.catch((err) => {
				console.log(err);
			});

		// Step 2: Remove from words2Add any that already exist
		function cleanup() {
			function removeIndexFromArray(i) {
				words2Add.splice(words2Add.indexOf(i), 1);
			}
			words2Add.forEach((word) => {
				if (wordsInFirestore.includes(word)) {
					removeIndexFromArray(word);
				}
			});
		}

		// Step 3: add all words to firestore
		function updatewordsInFirestore() {
			// working, adds array of words to rimas/words document
			firebase
				.firestore()
				.collection('rimas')
				.doc('words')
				.set({
					words: [...words2Add, ...wordsInFirestore],
				})
				.then(() => {
					console.log(
						'%c words[] in addWords():',
						DEBUG_GREEN,
						'Document successfully written!'
					);
					alert(`${words2Add.length} words were added successfully!`);
				})
				.catch((error) => {
					console.error('Error adding document: ', error);
				});
		}
	};

	const addPhrases = (phrases2Add) => {
		// WORKING! updates phrases document in firestore
		let obj = {};
		let phrasesInFirestore = [];

		// Step 1: get the current phrases array from Firestore
		firebase
			.firestore()
			.collection('rimas')
			.doc('phrases')
			.get()
			.then((doc) => {
				if (doc.exists) {
					obj = doc.data();
					console.log(obj);
					phrasesInFirestore = [...Array.from(obj.phrases)];
				} else {
					console.log('No such document!');
				}

				cleanup();
				updatePhrasesInFirestore();
			})
			.catch((err) => {
				console.log(err);
			});

		// Step 2: Remove from phrases2Add any that already exist
		function cleanup() {
			function removeIndexFromArray(i) {
				phrases2Add.splice(phrases2Add.indexOf(i), 1);
			}
			phrases2Add.forEach((word) => {
				if (phrasesInFirestore.includes(word)) {
					removeIndexFromArray(word);
				}
			});
		}

		// Step 3: add all phrases to firestore
		function updatePhrasesInFirestore() {
			// working, adds array of phrases to rimas/phrases document
			firebase
				.firestore()
				.collection('rimas')
				.doc('phrases')
				.set({
					phrases: [...phrases2Add, ...phrasesInFirestore],
				})
				.then(() => {
					console.log(
						'%c phrases[] in addphrases():',
						DEBUG_GREEN,
						'Document successfully written!'
					);
					alert(`${phrases2Add.length} phrases added successfully!`);
				})
				.catch((error) => {
					console.error('Error adding document: ', error);
				});
		}
	};
	//#endregion

	//#region
	// deletewords & deletePhrases
	// const deleteCustomer = (id) => {
	// 	firebase.firestore().collection('customers').doc(id).delete();
	// 	getCustomers();
	// };

	// updateWords & updateCustomers
	// const updateCustomer = (id, firstName, lastName) => {
	// 	firebase
	// 		.firestore()
	// 		.collection('customers')
	// 		.doc(id)
	// 		.update({ firstName, lastName });
	// 	getCustomers();
	// };
	//#endregion
	return (
		<FirebaseContext.Provider
			value={{
				rimas: state.rimas,
				words: state.words,
				phrases: state.phrases,
				getRimas,
				addWords,
				addPhrases,
				getWordsFromRimas,
				getPhrasesFromRimas,
			}}>
			{props.children}
		</FirebaseContext.Provider>
	);
};

export default FirebaseActions;
