import React, { useState, useEffect, useContext, useRef } from 'react';
import SongContext from '../utils/context/analyzeSongs/songContext';
import Results from './Results';

const AddLyricsForm = (props) => {
	// const [artist, setArtist] = useState('');
	// const [songTitle, setSongTitle] = useState('');
	const [lyr, setLyr] = useState('');
	const lyrics = useRef();
	const [showFullHeight, setShowFullHeight] = useState(false);
	const [showResults, setShowResults] = useState(false);
	const { cleanLyrics, words } = useContext(SongContext);

	const handleLyricInput = (e) => {
		setShowFullHeight(false);
		setLyr(lyrics.current.value);
	};

	// const handleChange = (e) => {
	// 	e.preventDefault();
	// 	let id = e.target.id;
	// 	let value = e.target.value;

	// 	switch (id) {
	// 		// case 'artist':
	// 		// 	setArtist(value);
	// 		// 	break;
	// 		// case 'songTitle':
	// 		// 	setSongTitle(value);
	// 		// 	break;

	// 		default:
	// 			break;
	// 	}
	// };

	const handleSubmit = (e) => {
		e.preventDefault();
		let isConfirmed = window.confirm(
			"Did you check every word's spelling is correct? If not, please do. It will help the search algorithm find rhymes."
		);

		if (isConfirmed) {
			cleanLyrics(lyr);
			setShowResults(true);
		}
	};

	useEffect(() => {
		const element = document.querySelector('#scroll-to-this-spot');
		words.length &&
			element.scrollIntoView({ behavior: 'smooth', block: 'start' });
		return () => {
			document.getElementById('add-song-form').reset();
		};
	}, []);

	return (
		<div>
			<div className='card'>
				<div className='content'>
					<h2>Add a New Song Lyrics:</h2>
					<form onSubmit={handleSubmit} id='add-song-form'>
						{/* <div className='row'>
							<label htmlFor='artist'>Artist:</label>
							<input
								type='text'
								name='artist'
								id='artist'
								onChange={handleChange}
							/>
						</div>
						<div className='row'>
							<label htmlFor='songTitle'>Song Title:</label>
							<input
								type='text'
								name='songTitle'
								id='songTitle'
								onChange={handleChange}
							/>
						</div> */}

						{/* // LYRICS */}
						<textarea
							onFocus={() => setShowFullHeight(true)}
							onBlur={handleLyricInput}
							ref={lyrics}
							placeholder='Paste in song lyrics here...'
							class={showFullHeight ? 'full-height' : ''}
							id='lyr'></textarea>

						{/* // Button */}
						{lyr ? (
							<button type='submit' className='primary'>
								Submit Song for Analysis
							</button>
						) : (
							<button type='submit' className='primary' disabled>
								Add sond to enable button
							</button>
						)}
					</form>
				</div>
			</div>
			<hr></hr> <span id='scroll-to-this-spot'></span>
			{showResults && <Results />}
		</div>
	);
};

export default AddLyricsForm;
