import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

const firebaseConfig = {
	apiKey: 'AIzaSyC7_N_G2gf7X3Sgi8xmKy4tGEpcx0LKExA',
	authDomain: 'personal-cape.firebaseapp.com',
	databaseURL: 'https://personal-cape.firebaseio.com',
	projectId: 'personal-cape',
	storageBucket: 'personal-cape.appspot.com',
	messagingSenderId: '809002526604',
	appId: '1:809002526604:web:927a63c82ff0e0a95218a9',
	measurementId: 'G-SNKPS4HF4Y',
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export default firebase;
