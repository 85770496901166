import React from 'react';
import { BrowserRouter, Route, Switch, Link } from 'react-router-dom';
// ===== UTILS =====
import '../utils/styles/App.css';
import FirebaseActions from '../utils/context/firebase/FirebaseActions';
import SongActions from '../utils/context/analyzeSongs/SongActions';
// ===== COMPONENTS =====
import Dashboard from './Dashboard';
import AddLyricsForm from './AddLyricsForm';
import Search from './Search';
// Google gets their lyrics from https://www.lyricfind.com/
// Use this for private routes: https://reacttraining.com/react-router/web/example/auth-workflow

function App() {
	return (
		<div className='App'>
			<BrowserRouter>
				<Link to='/'>
					<h1>R.M.A.</h1>
				</Link>
				<FirebaseActions>
					<SongActions>
						<Switch>
							<Route exact path='/'>
								<Dashboard />
							</Route>
							<Route path='/search'>
								<Search />
							</Route>
							<Route path='/add'>
								<AddLyricsForm />
							</Route>
						</Switch>
					</SongActions>
				</FirebaseActions>
			</BrowserRouter>
		</div>
	);
}

export default App;
