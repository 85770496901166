import React, { useContext, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
// import { DEBUG_BLUE } from '../utils/types';
import SongContext from '../utils/context/analyzeSongs/songContext';

const Li = (props) => {
	// console.log('%c In Li_Tag:', DEBUG_BLUE, props);
	const { removeWords, removePhrases } = useContext(SongContext);
	const [showLi, setShowLi] = useState(true);
	const [location, setLocation] = useState();

	let loc = useLocation();

	useEffect(() => {
		setLocation(loc);
	}, [loc]);

	const handleWordRemoval = () => {
		console.log(location.pathname === '/');
		if (location.pathname === '/') {
			console.log('do nothing');
		} else {
			if (props.words) {
				removeWords(props.value);
			}
			if (props.phrases) {
				removePhrases(props.value);
			}
			setShowLi(false);
		}
	};
	// const handlePhraseRemoval = () => {
	// 	removePhase(props.value);
	// 	setShowLi(false);
	// };

	return (
		<React.Fragment>
			{props.actions
				? showLi && (
						<li className='text-left cursor' onClick={handleWordRemoval}>
							<div className='d-inline'>{props.value}</div>
							<div className='d-inline'>
								<span className='delete text-right'>x</span>
							</div>
						</li>
				  )
				: showLi && (
						<li className='text-left'>
							<div className='d-inline'>{props.value}</div>
						</li>
				  )}

			{/* {showLi && (
				<li className='text-left cursor' onClick={handleWordRemoval}>
					<div className='d-inline'>{props.value}</div>
					{props.actions && (
						<div className='d-inline'>
							<span className='delete text-right'>x</span>
						</div>
					)}
				</li>
			)} */}
		</React.Fragment>
	);
};

export default Li;
