// conAcento
// sinAcento
const articulos = /\s(el|la|lo|los|las|un|uno|una|unos|unas|al|del|a|de)\s/gi;

const preposiciones = /\s(a|en|con|sin|por|para|pa|so|de)\s/gi;

const conjuciones = /\s(y|e|pero|aunque|bien|que|qué|si|no)\s/gi;

const interjecciones = /\s(eh|ah|yeah|oh|ey|hey|yo|yeah)\s/gi;

const artists = /\sbalvin|daddy|yankee|wisin|yandel|jam|nicky|\s/gi;

const randomWords = /\s(su|prr|brr|yeah|ey|wey|hey|oh|i|pai|girl|baby|bebe|mami|ma|mi|mí|te|ya|da|baila|ti|tu|tú|tus|esto|esta|estos|estas|to|me|ram|pam|es|esa|ese|esos|esas|wow|ve|you|know|are|love|ella|el|él|ellas|ellos|son|esta|fue|se)\s/gi;

/**
 * Elimina todos los artículos, preposiciones, conjunciones e interjecciones
 */
export const eliminaPalabrasInnecesarias = (lyrics) => {
	// console.info('LYRICS:', lyrics);
	//  const regex = /\s()\s/gi;

	return lyrics
		.replace(articulos, ' ')
		.replace(preposiciones, ' ')
		.replace(conjuciones, ' ')
		.replace(interjecciones, ' ')
		.replace(randomWords, ' ')
		.replace(artists, ' ');
};

// !TERMINACIONES
/**
 * Determina que el tipo de palabra que se pasa como param
 */
const enQueTermina = (params) => {
	let vocal, dobleVocal, consonante, consonanteN, consonanteS;
};

export const esAguda = (params) => {
	/* Las palabras agudas son las que llevan acento (la intensidad de la voz) en la última sílaba.

Importante destacar que no todas las palabras agudas llevan acento ortográfico (tilde).

Las palabras agudas llevan tilde si terminan en vocal:

Perú - sofá - café - rubí - menú - marroquí - bebé
Las palabras agudas llevan tilde si terminan en N o S:

también - algún - jamás - según - sillón - además - organización - capitán - alemán - anís - canción
Hay palabras agudas que tienen tilde a pesar de NO terminar en vocal, N o S. Esto es por la ruptura del diptongo:

Raúl, baúl, raíz, maíz
Ejemplos de palabras agudas CON tilde:

Perú - acción - sofá - café - organización - vudú - capitán - rubí - francés - sillón - camarón - país - japonés
Ejemplos de palabras agudas SIN tilde:

amor - cantidad - papel - reloj - capaz - pared - estoy - avestruz - virtud - fatal - contador */
};

export const esLlana = (word) => {
	/*
    Palabras llanas son las que tienen la sílaba tónica en penúltimo lugar. 
    Llevan tilde si no terminan en vocal, ni en n, ni en s. 
    
    Ejemplos: lápiz, difícil, González, móvil, césped, cráter, fénix, sílex, oso, marca, fruta, silla.

Excepción:
    a) Las palabras llanas terminadas en cualquier consonante seguida de n o de s llevan tilde: fórceps, bíceps, cómics.

    Observación:
Las palabras llanas terminadas en dos vocales abiertas se escriben sin tilde, pues son llanas terminas en vocal: zoo, posee, bacalao, Campoo, etc.*/
};
export const esEsdrujula = (word) => {
	/*
    Las palabras esdrújulas son las que llevan la intensidad de la voz en la antepenúltima sílaba.

Importantísimo destacar que en este caso todas las palabras se acentúan con el acento ortográfico (tilde) siempre.

Ejemplos de palabras esdrújulas:

América - Bélgica - Sudáfrica - música - miércoles - sílaba - máquina - gramática - económico - pájaro - séptimo - cuídate - brújula - gótico - hígado - ejército - características - cállate - dámelo - fósforo - cáscara - cerámica - oxígeno - didáctico - válido
*/
};

export const esSobreEsdrujula = (word) => {
	/*
	 */
};
