import React, { useContext, useState } from 'react';
import FirebaseContext from '../utils/context/firebase/firebaseContext';
// import {
// 	DEBUG_GREEN,
// 	DEBUG_GREY,
// 	DEBUG_BLUE,
// 	DEBUG_RED,
// 	DEBUG_YELLOW,
// } from '../utils/types';

// ===== COMPONENTS =====
import Li from '../components/Li_Tag';
import { useEffect } from 'react';

const Search = (props) => {
	const {
		words,
		phrases,
		getRimas,
		getWordsFromRimas,
		getPhrasesFromRimas,
	} = useContext(FirebaseContext);
	const useFRun = 'once';
	const [search, setSearch] = useState('');
	const [filteredWords, setFilteredWords] = useState(false);
	const [filteredPhrases, setFilteredPhrases] = useState(false);

	// const [isConstante, setIsConstante] = useState(false);
	// const [isAsonante, setIsAsonante] = useState(false);

	useEffect(() => {
		getRimas();
	}, [useFRun]);

	const updSearch = (e) => {
		setSearch(e.target.value);
	};

	const handleSearch = (e) => {
		e.preventDefault();
		// is word: esdrújulas, agúdas y llanas
		// https://stackoverflow.com/questions/990904/remove-accents-diacritics-in-a-string-in-javascript
		if (search.length < 5) {
			words &&
				setFilteredWords(
					words.filter(
						(word) =>
							word.slice(word.length - 3) === search.slice(search.length - 3)
					)
				);
		} else {
			words &&
				setFilteredWords(
					words.filter(
						(word) =>
							word.slice(word.length - 4) === search.slice(search.length - 4)
					)
				);
		}

		phrases &&
			setFilteredPhrases(
				phrases.filter((phrase) => phrase.indexOf(search) > -1)
			);

		console.log(filteredWords);
		console.log(filteredPhrases);
	};
	return (
		<React.Fragment>
			<div className='card'>
				<div className='content'>
					<form onSubmit={handleSearch}>
						<input type='text' id='search' onKeyUp={updSearch} />
						<button className='primary' type='submit'>
							Search
						</button>
					</form>
					<button className='btn secondary' onClick={getWordsFromRimas}>
						Request Words
					</button>
					<button className='btn secondary' onClick={getPhrasesFromRimas}>
						Request Phrases
					</button>
					<button className='btn primary' onClick={getRimas}>
						Request Rimas
					</button>
				</div>
			</div>
			{/* Search results here: */}
			<div className='output'>
				<div className='card words'>
					<h3>Words</h3>
					<ul className='content'>
						{filteredWords &&
							filteredWords.map((file, i) => {
								return <Li key={i} value={file} />;
							})}
					</ul>
				</div>
				<div className='card phrases'>
					<h3>Phrases</h3>
					<ul className='content'>
						{filteredPhrases &&
							filteredPhrases.map((file, i) => {
								return <Li key={i} value={file} actions={false} />;
							})}
					</ul>
				</div>
			</div>

			<div className='card'>
				<div className='content'>
					<a href='http://cea.cide.edu/gramtica/tipos_de_palabras.html'>
						palabras
					</a>
					<a href='https://3.bp.blogspot.com/-Y2dI4xJNNRw/XGBjbPKy7bI/AAAAAAAAAKk/zoLokXa-5is3dE0_HDRsQTWpM0ezWBAIgCLcBGAs/s1600/clases%2Bde%2Bpalabras.jpg'>
						{' '}
						Clases de Palabras (IMAGE)
					</a>
					<a href='https://www.claseslengua.com/2019/02/clases-de-palabras-y-grupos-de-palabras.html'>
						Sintagmas
					</a>
					<a href='https://www.gramaticas.net/2012/11/tipos-de-palabras.html'>
						Tipos de palabras
					</a>
					<a href='https://www.gramaticas.net/2010/09/ejemplos-de-sintagma.html'>
						Ejemplos de Sintagma
					</a>
					<a href='https://www.edu.xunta.gal/centros/iesillaons/system/files/CLASES+DE+PALABRAS.+(T).pdf'>
						PDF
					</a>

					<br />
				</div>
			</div>
		</React.Fragment>
	);
};

export default Search;
