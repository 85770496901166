// Types are nothing more than just variables of strings that you can call to change your state within the reducer
// Because types are relevant to all context that we create, the types.js needs to be in the root context folder
// Basically all of our actions have types

//#region   ========== DEBUGGING TOOLS ==========
// import {
// 	'%c THING', DEBUG_GREEN,
// 	'%c THING', DEBUG_GREY,
// 	'%c THING', DEBUG_YELLOW,
// 	'%c THING', DEBUG_BLUE,
// 	'%c THING', DEBUG_RED,
// } from '
export const DEBUG_BLUE =
	'background: #8A8AFF; color: #212121; padding: 0.5vh 2vmax;';
export const DEBUG_GREY =
	'background: #f0f0f0; color: #212121; padding: 0.5vh 2vmax;';
export const DEBUG_GREEN =
	'background: #89ff89; color: #212121; padding: 0.5vh 2vmax;';
export const DEBUG_RED =
	'background: #ff7676; color: #212121; padding: 0.5vh 2vmax;';
export const DEBUG_YELLOW =
	'background: #ffff62; color: #212121; padding: 0.5vh 2vmax;';
//#endregion

//#region   ========== ACTIONS ==========

export const GET_RIMAS = 'GET_RIMAS';
export const GET_WORDS_ARRAY = 'GET_WORDS_ARRAY';
export const GET_PHRASES_ARRAY = 'GET_PHRASES_ARRAY';
export const SET_LOADING = 'SET_LOADING';
//#endregion
